import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RESET_SPECIFIC_CATEGORIES } from "../../store/Types/CategoryTypes";
import {
  addCategory,
  getCategory,
  getspecificCategory,
  updateCategory,
} from "../../store/AsyncMethods/CategoryMethod";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RESET_ERROR } from "../../store/Types/AuthTypes";
import { toast } from "react-toastify";
import { Divider } from "primereact/divider";
import InputFocus from "../../hooks/InputFocus";

export default function EditCategories() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const inputRef = InputFocus();
  const { error } = useSelector((state) => state.AuthReducer);
  const { specificCategory } = useSelector((state) => state.CategoryReducer);

  useEffect(() => {
    if (specificCategory) {
      if (specificCategory?.name) {
        formik.setFieldValue("category", specificCategory.name);
      } else {
        formik.setFieldValue("category", "");
      }
    }
  }, [specificCategory]);
  const formik = useFormik({
    initialValues: {
      category: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Category Required"),
    }),

    onSubmit: async (data) => {
      dispatch(updateCategory(data, id)).then((success) => {
        if (success) {
          formik.resetForm();
          dispatch(getCategory());
          dispatch({ type: RESET_SPECIFIC_CATEGORIES });
          navigate("/categories");
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getspecificCategory(id));
  }, []);

  return (
    <div className="mx-4">
      <div>
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-black-btn"
          onClick={() => navigate(-1)}
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="card shadow-md rounded-lg p-4 mt-4">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              Edit Category
            </span>
          </Divider>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-4 gap-8">
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="category" className="">
                  Enter Category
                </label>
                <span className=" w-full">
                  <InputText
                    ref={inputRef}
                    id="category"
                    name="category"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.category && formik.errors?.category && (
                <div className="p-error">{formik.errors?.category}</div>
              )}
            </div>
          </div>
          <div className="mt-16">
            <div className="flex justify-end gap-4">
              <Button
                label="Clear"
                icon="pi pi-times"
                className="p-red-btn w-28"
                type="button"
                onClick={() => {
                  formik.resetForm();
                  dispatch({ type: RESET_SPECIFIC_CATEGORIES });
                }}
              />
              <Button
                label={"Edit"}
                icon="pi pi-check"
                className="p-secondary-btn w-28"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
